.react-datepicker-wrapper,
.react-datepicker__input-container{border:0}
.react-datepicker__input-container input {
    display: block;
    width: 100%;
    border-radius: 4px;
    border-style: solid;
    border-width: 0.5px;
    border-color: hsl(0, 0%, 80%);
    min-height: 38px;
    box-sizing: border-box;
    cursor: default;
    outline: 0 !important;
    justify-content: space-between;
    padding-left: 5px;
}
.react-datepicker__input-container input:hover {
    /* background-color: blueviolet; */
    border-color: #34aadc;
}